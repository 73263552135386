<template>
  <div class="test-paper">
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <div
                style="
                  flex: 1;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                第{{ currentIndex + 1 }}题
                <span>{{ currentIndex + 1 }}/{{ testList.length }}</span>
              </div>
              <div
                style="
                  flex: 1;
                  text-align: right;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                {{ runLastTimeText }}
              </div>
            </div>
          </a-col>
          <div class="test-content">
            <template v-if="empty">
              <a-row
                type="flex"
                justify="center"
                style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
              >
                <el-empty :description="description"></el-empty>
              </a-row>
            </template>
            <template v-else>
              <a-row
                :gutter="24"
                style="margin-top: 20px; min-height: 350px"
                v-loading="loading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.2)"
              >
                <!-- 测试题 -->
                <div class="test">
                  <div
                    v-for="(item, index) in testList"
                    :key="index"
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                      v-if="index === currentIndex ? true : false"
                      class="single-question"
                    >
                      {{ item.questionContent }}
                      <span class="score">({{ item.score + "分" }})</span>
                      <el-tag size="mini" style="margin-left: 10px">{{
                        item.type === 1
                          ? "单选题"
                          : item.type === 2
                          ? "多选题"
                          : "简答题"
                      }}</el-tag>
                      <div style="margin-top: 10px">
                        <div
                          style="text-align: left"
                          v-for="(v, i) in item.optionList"
                          :key="i"
                        >
                          <div
                            class="options"
                            v-if="item.type == 1 ? true : false"
                          >
                            <!-- 单选题 -->
                            <el-radio-group
                              @change="handleAnswer"
                              v-model="item.getAnswer"
                            >
                              <el-radio :label="item.optionList[i].optionId"
                                >{{ v.optionFlag }}、{{
                                  v.optionContent
                                }}</el-radio
                              >
                            </el-radio-group>
                          </div>
                          <!-- 多选题 -->
                          <div
                            class="options"
                            v-if="item.type === 2 ? true : false"
                          >
                            <el-checkbox-group
                              v-model="checkList"
                              @change="handleCheck(v, i)"
                              v-if="item.type === 2 ? true : false"
                            >
                              <el-checkbox
                                :label="item.optionList[i].optionId"
                                :checked="checkTestFlag"
                                >{{ v.optionFlag }}、{{
                                  v.optionContent
                                }}</el-checkbox
                              >
                            </el-checkbox-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 切换题目 -->
                <div
                  class="btn"
                  style="
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-around;
                  "
                >
                  <!-- 抽屉 -->
                  <div class="all-test">
                    <el-button
                      @click="drawer = true"
                      type="primary"
                      style="margin-left: 16px"
                    >
                      所有题目
                    </el-button>

                    <el-drawer
                      title="所有题目"
                      :visible.sync="drawer"
                      :direction="direction"
                      :before-close="handleClose"
                    >
                      <div class="tag-test">
                        <el-button
                          v-for="(item, index) in tagTest"
                          :key="index"
                          @click="handleTest(index)"
                          :class="
                            testList[index].getAnswer ||
                            testList[index].optionList.some((v) => {
                              return v.checkTestFlag === true;
                            })
                              ? 'tagBtn'
                              : ''
                          "
                          >{{ item }}</el-button
                        >
                      </div>
                    </el-drawer>
                  </div>
                  <div>
                    <el-button @click="preBtn()" v-if="currentIndex != 0"
                      >上一题</el-button
                    >
                    <el-button
                      type="primary"
                      @click="nextBtn()"
                      v-if="currentIndex === testList.length - 1 ? false : true"
                      >下一题</el-button
                    >
                    <el-popconfirm
                      title="题目还未答完，确定要提交吗？"
                      @confirm="submitTest"
                      v-if="
                        !allAnswerItem && currentIndex === testList.length - 1
                          ? true
                          : false
                      "
                    >
                      <el-button type="success" slot="reference"
                        >提交</el-button
                      >
                    </el-popconfirm>
                    <el-button
                      type="primary"
                      @click="submitTest()"
                      v-if="
                        allAnswerItem && currentIndex === testList.length - 1
                          ? true
                          : false
                      "
                      >提交</el-button
                    >
                    <el-dialog
                      title="答题结束"
                      :visible.sync="dialogVisible"
                      width="30%"
                      :before-close="getScorehandleClose"
                      destroy-on-close
                    >
                      <div>分数：{{ score }}</div>
                      <div>用时：{{ sumTime }}</div>
                      <span slot="footer" class="dialog-footer">
                        <!-- <el-button @click="dialogVisible = false"
                          >取 消</el-button
                        > -->
                        <el-button type="primary" @click="handleCompeteTest()"
                          >确 定</el-button
                        >
                      </span>
                    </el-dialog>
                  </div>
                </div>
              </a-row>
            </template>
          </div>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      testList: [],
      loading: true,
      empty: false,
      description: "暂无数据",
      currentIndex: 0,
      getAnswer: "",
      checkIds: [], // 用户选择时的选项ID列表
      remainingTime: 0,
      totalTime: 0,

      tagTest: [],
      // 所有题目数据
      drawer: false,
      direction: "btt",
      timer: null,

      allAnswer: [], // 所有问题的答案 提交答案时所需的answer的参数
      dialogVisible: false,
      score: "",
      sumTime: "",

      checkList: [],
      checkTestFlag: false,
    };
  },
  created() {
    this.remainingTime = this.$route.query.testTime * 60 * 1000;
    this.totalTime = this.remainingTime;
    this.getTestContent();
  },
  computed: {
    ...mapState(["fullWidth", "specialId", "userFlagId"]),

    currentQuestion() {
      return this.testList[this.currentIndex];
    },

    allAnswerItem() {
      return this.allAnswer.every((v, i) => {
        return v.optionId.length >= 1;
      });
    },

    // 倒计时
    runLastTimeText() {
      if (this.remainingTime < 0) {
        return "";
      }
      if (this.remainingTime == 0) {
        return "时间到";
      }
      console.error("倒计时", this.remainingTime);
      let res = "";
      if (this.remainingTime < 60 * 1000) {
        let m = Math.floor(this.remainingTime / 1000);
        res = "00:" + (m > 9 ? "" + m : "0" + m);
      } else if (this.remainingTime < 60 * 60 * 1000) {
        let h = Math.floor(this.remainingTime / (60 * 1000));
        let m = Math.floor((this.remainingTime % (60 * 1000)) / 1000);
        res = (h > 9 ? "" + h : "0" + h) + ":" + (m > 9 ? "" + m : "0" + m);
      } else {
        let d = Math.floor(this.remainingTime / (60 * 60 * 1000));
        let h = Math.floor(
          (this.remainingTime % (60 * 60 * 1000)) / (60 * 1000)
        );
        let m = Math.floor(
          ((this.remainingTime % (60 * 60 * 1000)) % (60 * 1000)) / 1000
        );
        res =
          (d > 9 ? "" + d : "0" + d) +
          ":" +
          (h > 9 ? "" + h : "0" + h) +
          ":" +
          (m > 9 ? "" + m : "0" + m);
      }
      return res;
    },
  },
  methods: {
    // 获取测试题
    getTestContent() {
      // console.log("this.check", this.isCheckList);
      // console.log("iddddddddddd", this.specialId);
      this.$HTTP
        .httpToken({
          url: "/wst/testPaper/getTestPaper",
          method: "post",
          data: {
            topicId: this.$route.query.topicId,
          },
        })
        .then((res) => {
          // console.log("题目数据", res);
          this.loading = false;
          if (res.code == 0 && res.data && res.data.length > 0) {
            this.empty = false;
            this.testList = res.data;

            this.testList.forEach((v, i) => {
              v.answerContent = {
                questionId: v.questionId,
                type: v.type,
                optionId: [],
              };
              v.optionList.forEach((v) => {
                v.checkTestFlag = false;
              });
              this.allAnswer.push(v.answerContent);
            });
            // console.log("allanswer", this.allAnswer);
            // console.log("this.test", this.testList);

            this.allAnswer.forEach((v, i) => {
              if (v.type === 2) {
                v.checkTestFlag = false;
              }
            });

            // 获取到题目数据后立即触发倒时器
            this.timer = setInterval(() => {
              this.remainingTime -= 1000;
              this.testRunLastTime();
            }, 1000);

            // 所有题目序号数组数据
            for (let i = 1; i <= this.testList.length; i++) {
              this.tagTest.push(i);
            }
          } else {
            if (this.pageIndex == 1) {
              this.empty = true;
              this.description = "暂无数据";
            } else {
              this.empty = false;
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          if (this.pageIndex == 1) {
            this.empty = true;
            this.description = e;
          } else {
            this.empty = false;
          }
        });
    },

    // 选中答案
    // 单选题
    handleAnswer(value) {
      let newArr = this.currentQuestion.optionList.map((v, i) => {
        return v.optionId;
      });
      let index = newArr.indexOf(value);
      this.testList.forEach((v, i) => {
        if (this.currentIndex === i) {
          v.getAnswer = v.optionList[index].optionId;
          // 先判断this.allAnswer数组中的optionId中是否有值，如果有值就删除掉原来那个，再加上最新点击的这个
          if (this.allAnswer[i].optionId.length > 0) {
            this.allAnswer[i].optionId.splice(
              0,
              this.allAnswer[i].optionId.length
            );
            this.allAnswer[i].optionId.push(v.getAnswer);
          } else {
            this.allAnswer[i].optionId.push(v.getAnswer);
          }
        }
      });
    },

    // 多选题
    handleCheck(value, index) {
      this.testList.forEach((v, i) => {
        if (this.currentIndex === i) {
          value.checkTestFlag = !value.checkTestFlag;
          if (value.checkTestFlag) {
            this.allAnswer[i].optionId.push(value.optionId);
            // console.log("this.allAnswer", this.allAnswer[i].optionId);
          } else {
            let temp = this.allAnswer[i].optionId;
            this.allAnswer[i].optionId.splice(temp.indexOf(value.optionId), 1);
            // console.log("this.allAnswer", this.allAnswer[i].optionId);
          }
        }
      });
      // console.log("value", value);
      // console.log("index", index);
      // console.log("allAnswer", this.allAnswer);
    },

    // 上一题
    preBtn() {
      if (this.currentIndex != 0) {
        this.currentIndex--;
      }
    },

    // 下一题
    nextBtn() {
      if (this.currentIndex < this.testList.length - 1) {
        this.currentIndex++;
      }
      console.log(this.allAnswerItem);
    },

    // 所有题目方法占位
    handleClose(done) {
      this.drawer = false;
    },

    // 点击题目序号去到对应的题目
    handleTest(i) {
      this.currentIndex = i;
      this.drawer = false;
    },

    // 检测倒计时
    testRunLastTime() {
      if (this.remainingTime > 0) {
        return;
      }
      clearInterval(this.timer);
      this.submitTest();
      this.dialogVisible = true;
    },

    // 提交试卷
    submitTest() {
      clearInterval(this.timer);
      // 累计花费时间
      let surplusTime = (this.totalTime - this.remainingTime) / 1000;
      this.$HTTP
        .httpToken({
          url: "/wst/testPaper/finishTestPaper",
          method: "post",
          data: {
            userFlagId: this.userFlagId,
            answer: this.allAnswer,
            answerStartToEndTime: surplusTime,
          },
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data) {
            this.dialogVisible = true;
            this.score = res.data.score;
            this.sumTime = this.toTimeStr(surplusTime * 1000);
          }
        })
        .catch((e) => {});
    },

    // 提交试卷后点击显示分数框外的区域
    getScorehandleClose(done) {
      this.$elementConfirm("确定退出答题吗？")
        .then((_) => {
          done();
          this.$router.push("/specialclass");
        })
        .catch((_) => {});
    },

    /*转换时间*/
    toTimeStr(secTotal) {
      let result = null;
      secTotal = Math.floor(secTotal / 1000);
      let hour = Math.floor(secTotal / 3600);
      let min = Math.floor((secTotal % 3600) / 60);
      let sec = Math.floor((secTotal % 3600) % 60);
      if (hour == 0 && min == 0) {
        result = this.to2Str(sec) + "秒";
      } else if (hour == 0) {
        result = this.to2Str(min) + "分" + this.to2Str(sec) + "秒";
      } else {
        result =
          this.to2Str(hour) +
          "时" +
          this.to2Str(min) +
          "分" +
          this.to2Str(sec) +
          "秒";
      }
      return result;
    },
    to2Str(i) {
      if (i > 9) {
        return i + "";
      } else {
        return "0" + i;
      }
    },

    // 查看到分数后点击确定按钮跳往专题课程页面
    handleCompeteTest() {
      if (this.$route.query.app_no) {
        this.dialogVisible = false;
        this.$router.push("/legalcompetition/legalcard");
      } else {
        this.dialogVisible = false;
        this.$router.push("/SpecialClass");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tagBtn {
  border-color: #37b9ff;
  color: #37b9ff;
}
.test {
  height: 300px;
  position: relative;
}
::v-deep .el-drawer {
  height: 40% !important;
}
.tag-test {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .el-button {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
}
.single-question {
  text-align: left;
  font-size: 20px;
}

.el-checkbox-group,
.el-radio-group {
  height: 50px;
  line-height: 50px;
  .el-checkbox,
  .el-radio {
    height: 50px;
    line-height: 50px;
  }
}
</style>